<template>
	<div class="main">
		<div class="header">
			<img class="header-logo-ci" src="@/assets/image/zhfe/zhfCI.png" />
			<img class="header-logo" src="@/assets/image/zhfe/zhfelogo.png" />
			<span class="header-txt">智惠妇儿</span>
			<!-- 关闭 刷新 -->
			<div class="idx-middlesx-box3">
				<div class="idx-middle-1">
					<img src="@/assets/image/zhfe/return.png" @click="getback" />
					<img src="@/assets/image/zhfe/icon/sx.png" alt="" @click="refresh" />
				</div>
			</div>
		</div>
		<div class="time-box">
			<div class="filter-wrap">
				<el-select v-model="yearValue" clearable placeholder="所属年份">
					<el-option v-for="item in yearOptions" :key="item" :label="item" :value="item"> </el-option>
				</el-select>
				<div class="line">——</div>
				<el-select v-model="quarter" clearable placeholder="所属季度">
					<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
				</el-select>
				<el-button type="primary" class="other" @click="onSearch">查询</el-button>
			</div>
		</div>
		<div class="container">
			<div class="statistics-wrap">
				<div class="module module_1">
					<div class="badge badge_1"></div>
					<div class="badge badge_2"></div>
					<div class="badge badge_3"></div>
					<div class="badge badge_4"></div>
					<div class="headline-wrap">
						<div class="headline">
							<p class="headline-text">重点人群结对帮扶</p>
						</div>
					</div>
					<div class="module-content">
						<div class="content content_1">
							<div class="legend-wrap">
								<div
									class="legend-item"
									v-for="item in crowd"
									:key="item.value"
									:style="{ backgroundColor: item.color }"
								>
									<div class="label">{{ item.type }}</div>
									<div class="number">{{ item.value }}</div>
								</div>
							</div>
						</div>
						<div class="content content_2">
							<Ring :crowd="crowd" />
						</div>
					</div>
				</div>
				<div class="line">
					<img src="@/assets/image/zhfe/hometipsShu.png" alt="" />
				</div>
				<div class="module module_2">
					<div class="badge badge_1"></div>
					<div class="badge badge_2"></div>
					<div class="badge badge_3"></div>
					<div class="badge badge_4"></div>
					<div class="headline-wrap">
						<div class="headline" @click="handleRouteJumps('/one-to-one')">
							<p class="headline-text">“两类”女童一对一帮扶</p>
						</div>
					</div>
					<div class="module-content">
						<div class="area-wrap">
							<div class="area-item" v-for="item in areaList" :key="item.helpArea">
								<div class="data">
									<div class="molecule">{{ item.helpUserCount }}&nbsp;</div>
									<div class="denominator">/&nbsp;{{ item.helpCount }}</div>
								</div>
								<div class="label">
									<div class="name">{{ item.helpArea }}</div>
									<div class="tip">
										(
										<div class="molecule">人数</div>
										<div class="denominator">/帮扶次数</div>
										)
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="module module_3">
					<div class="badge badge_1"></div>
					<div class="badge badge_2"></div>
					<div class="badge badge_3"></div>
					<div class="badge badge_4"></div>
					<div class="headline-wrap">
						<div class="headline" @click="handleRouteJumps('/assist')">
							<p class="headline-text">探访</p>
						</div>
					</div>
					<div class="module-content">
						<GradualHistogram :crowd="visit" :id="'histogram_3'" />
					</div>
				</div>
			</div>
			<div class="table-wrap">
				<div class="table">
					<div class="table-label" style="background-color: transparent"></div>
					<div class="table-data">
						<div class="table-row-wrap head" @click="handleRouteJumps('/investigine')">
							<div class="table-row"></div>
							<div class="table-row" style="white-space: pre-wrap" v-for="item in area" :key="item">{{ item && item.length === 4 ? item.slice(0, 2) + '\n' + item.slice(2) : item }}</div>
						</div>
					</div>
				</div>
				<div class="table" v-for="table in list" :key="table.personType">
					<div class="table-label">{{ table.personType }}</div>
					<div class="table-data">
						<div class="table-row-wrap" v-for="list in table.list" :key="list.personType2">
							<div class="table-row">{{ list.personType2 }}</div>
							<div class="table-row" v-for="item in list.list" :key="item.area">
								{{ item.personCount == 0 ? '' : item.personCount }}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import Ring from './components/ring/index';
import GradualHistogram from './components/gradual-histogram/index.vue';
import { getList, getRecord, getRecordYear, getbangfu, getNewDataRecord } from '@/api/seedlings';

export default {
	components: { Ring, GradualHistogram },
	data() {
		return {
			crowd: [],
			visit: [],
			area: [],
			filterDate: '',
			list: [],
			yearOptions: [],
			options: [
				{
					value: 1,
					label: '第一季度',
				},
				{
					value: 2,
					label: '第二季度',
				},
				{
					value: 3,
					label: '第三季度',
				},
				{
					value: 4,
					label: '第四季度',
				},
			],
			quarter: '',
			yearValue: '',
			areaList: [],
		};
	},
	methods: {
		handleRouteJumps(path) {
			if (!this.existSystem()) {
				return false;
			}
			this.$router.push({ path });
		},
		refresh() {
			this.$router.go(0); //刷新当前页面
		},
		getback() {
			this.$router.go(-1); //刷新当前页面
		},
		async getNewDataRecord() {
			try {
				const {
					data: { helpYear, helpQuarter },
				} = await getNewDataRecord();
				this.yearValue = helpYear;
				this.quarter = helpQuarter;
				return Promise.resolve();
			} catch {
				return Promise.reject();
			}
		},
		*getData() {
			const params = { helpYear: this.yearValue, helpQuarter: this.quarter };
			yield getbangfu(params);
			yield getList(params);
			yield getRecord(params);
			yield getRecordYear(params);
			yield getbangfu(params);
		},
		onSearch() {
			const [start, end] = this.filterDate;
			this.initData();
		},
		async initData() {
			const getdatas = this.getData();
			const { data: crowd } = await getdatas.next().value;
			this.visit = crowd.map((item) => ({ type: item.personType, value: item.watchCount })).filter(item=>item.value!=0);
			const { data } = await getdatas.next().value;
			this.list = data;
			this.crowd = data.map((item, index) => ({
				type: item.personType,
				value: item.list[0].list.reduce((prev, next) => prev + next.personCount, 0),
				color: index === 0 ? '#FFB3CF' : index === 1 ? '#FF6BA2' : '#8E1B46',
			})).filter(item=>item.value!=0);
			this.area = data[0].list[0].list.map((item) => item.area);
			const { data: area } = await getdatas.next().value;
			this.areaList = area;
			const { data: year } = await getdatas.next().value;
			this.yearOptions = year;
			await getdatas.next().value;
		},
	},
	async mounted() {
		await this.getNewDataRecord();
		this.initData();
	},
};
</script>
<style lang="scss" scoped>
@import '../zhfeHome/index.scss';
.main {
	overflow: hidden;
}
.filter-wrap {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	::v-deep .el-select {
		width: 138px;
		.el-input__inner {
			width: 100%;
			margin-right: 20px;
			height: 38px;
			background: linear-gradient(270deg, rgba(205, 128, 162, 0), rgba(216, 149, 178, 0.17), rgba(205, 128, 162, 0));
			border: 1px solid #ffd4e4;
			border-radius: 0;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ffb3cf;
			line-height: 32px;
			&::placeholder {
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffb3cf;
				line-height: 32px;
			}
		}
	}
	.line {
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffb3cf;
		line-height: 32px;
		margin: 0 12px;
	}
	@media screen and (max-width: 1024px) {
		::v-deep .el-select {
			width: 98px;
			.el-input__inner {
				margin-right: 0px;
				height: 28px;
				line-height: 22px;
				font-size: 14px;
				&::placeholder {
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #ffb3cf;
					line-height: 22px;
				}
			}
			.el-input__icon {
				line-height: 22px;
			}
		}
		.line {
			font-size: 0.5vw;
			margin: 0 2px;
		}
	}
}
.el-button--primary {
	width: 68px;
	height: 38px;
	background: rgba(255, 212, 228, 0.4);
	border: 1px solid #ffd4e4;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: rgba(238, 168, 196, 1);
	border-radius: 0px;
	margin-left: 12px;
	&.other {
		clip-path: polygon(
			0 0,
			100% 0,
			100% 0,
			100% calc(100% - 12px),
			calc(100% - 12px) 100%,
			0 calc(100% + 12px),
			0 calc(100% + 12px),
			-2px -2px
		);
		background: linear-gradient(-45deg, #ffd4e4 8px, rgba(255, 212, 228, 0.4) 0) bottom right,
			linear-gradient(45deg, rgba(255, 212, 228, 0.4) 8px, rgba(255, 212, 228, 0.4) 0) bottom left,
			linear-gradient(135deg, rgba(255, 212, 228, 0.4) 8px, rgba(255, 212, 228, 0.4) 0) top left,
			linear-gradient(-135deg, rgba(255, 212, 228, 0.4) 8px, rgba(255, 212, 228, 0.4) 0) top right;
		background-size: 50% 50%;
		background-repeat: no-repeat;
	}
	@media screen and (max-width: 1024px) {
		height: 28px;
		padding: 0;
		&.other {
			background-size: 49.9% 49.9%;
		}
	}
}
.container {
	padding: 2vw;
	.statistics-wrap {
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex-wrap: wrap;
	}
	.module {
		border: 2px solid #fcd2e2;
		position: relative;
		.badge {
			&:before,
			&:after {
				content: '';
				position: absolute;
				top: 8px;
				left: -6px;
				width: 30px;
				height: 2px;
				background-color: #fcd2e2;
				transform: rotate(-45deg);
			}
			&:after {
				top: 16px;
				left: -8px;
				width: 52px;
			}
			&.badge_1 {
				position: absolute;
				top: 0;
				left: 0;
			}
			&.badge_2 {
				position: absolute;
				top: 0;
				right: 36px;
				&:before,
				&:after {
					top: 16px;
					left: -8px;
					transform: rotate(45deg);
				}
				&:before {
					left: 12px;
					top: 8px;
				}
			}
			&.badge_3 {
				position: absolute;
				bottom: 36px;
				left: 0;
				&:before,
				&:after {
					top: 18px;
					left: -8px;
					transform: rotate(45deg);
				}
				&:before {
					left: -6px;
					top: 26px;
				}
			}
			&.badge_4 {
				position: absolute;
				bottom: 36px;
				right: 36px;
				&:before,
				&:after {
					top: 18px;
					left: -8px;
				}
				&:before {
					left: 12px;
					top: 26px;
				}
			}
		}
		.headline-wrap {
			display: flex;
			justify-content: center;

			.headline {
				min-width: 10vw;
				width: max-content;
				padding: 12px 8px;
				display: flex;
				align-items: center;
				justify-content: center;
				height: 5vh;
				background: #cd80a2;
				border-radius: 0px 0px 14px 14px;
				background-size: 16px 16px;
				border: 2px solid #fcd2e2;
				border-top: none;

				.icon {
					width: 30px;
					height: 30px;
					margin-right: 12px;
				}
				.headline-text {
					font-family: 'Microsoft YaHei';
					font-weight: bold;
					font-size: 1.2vw;
					font-family: Microsoft YaHei;
					font-weight: bold;
					color: #ffffff;
				}
			}
		}

		&.module_1 {
			width: 26%;
			height: 24vh;
			position: relative;
			border-top: none;
			padding: 0 1vw;
			.badge_1,
			.badge_2 {
				top: -44px;
			}
			.headline-wrap {
				position: absolute;
				border: 2px solid #fcd2e2;
				border-bottom: none;
				top: -46px;
				left: -2px;
				width: calc(100% + 4px);
			}
			.module-content {
				display: flex;
				align-items: center;
				height: 100%;
				.content_1,
				.content_2 {
					height: 100%;
					overflow: hidden;
					display: flex;
					flex-direction: column;
					justify-content: space-around;
					&.content_1 {
						width: 40%;
						.legend-wrap {
							display: flex;
							height: 100%;
							flex-direction: column;
							justify-content: space-around;
							padding-top: 2vh;
							width: 100%;
							.legend-item {
								width: 100%;
								display: flex;
								align-items: center;
								justify-content: space-between;
								padding: 8px 0;
								color: #fff;
								font-size: 1vw;
								font-family: Adobe Heiti Std;
								font-weight: normal;
								color: #ffffff;
								font-weight: 600;
								padding: 1vh 1vw;

								.label {
									display: flex;
									align-items: flex-end;
									font-family: Adobe Heiti Std;
									font-weight: normal;
									color: #ffffff;
									font-weight: 600;
								}
								.number {
									font-size: 1.4rem;
									font-family: 'hy';
									color: #ffffff;
								}
							}
						}
					}
					&.content_2 {
						flex: 1;
					}
				}
				.line {
					width: 2px;
					height: 80%;
					background: linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.37), rgba(255, 255, 255, 0.1));
				}
			}
			@media screen and (max-width: 1024px) {
				.headline-wrap {
					top: -40px;
				}
				.badge_1,
				.badge_2 {
					top: -38px;
				}
			}
		}
		&.module_2,
		&.module_3 {
			height: 24vh;
			.module-content {
				height: calc(100% - 62px);
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
		&.module_2 {
			width: 46%;
			.headline-wrap {
				/* justify-content: space-around; */

				.headline {
				}
			}
			.module-content {
				.area-wrap {
					height: 100%;
					width: 100%;
					display: grid;
					grid-template-columns: repeat(5, 2fr);
					color: #ffb3cf;
					padding-top: 2vh;

					.area-item {
						display: flex;
						flex-direction: column;
						justify-content: flex-start;
						align-items: flex-start;
						font-family: 'hy';
						padding-left: 1vw;
						font-weight: 600;
						white-space: nowrap;
						.data,
						.label {
							display: flex;
							align-items: center;
							font-size: 1vw;
							&.data {
								margin-bottom: 1vh;
								font-size: 1.2vw;
								.molecule {
									color: #ffb3cf;
								}
								.denominator {
									color: #fb8ab4;
								}
							}
							&.label {
								font-family: Adobe Heiti Std;
								font-size: 0.8vw;
								color: #e6598e;
								.tip {
									display: flex;
									align-items: center;
									font-size: 0.7vw;
									color: #ffb3cf;
									.denominator {
										color: #fb8ab4;
									}
								}
							}
							@media screen and (max-width: 1400px) {
							}
						}
					}
				}
			}
		}
		&.module_3 {
			width: 24%;
		}
	}
	.line {
		img {
			height: 24vh;
			display: block;
		}
	}
	.table-wrap {
		width: 100%;
		border: 2px solid #fcd2e2;
		margin-top: 2vh;
		height: 56vh;
		padding: 1vh 0.2vw;
		display: flex;
		flex-direction: column;
		overflow-y: auto;
		.table {
			display: flex;
			width: 100%;
			height: max-content;
			margin-bottom: 0.5vh;

			.table-label {
				width: 46px;
				background-color: rgba(180, 75, 122, 0.2);
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ffb3cf;
				margin-right: 2px;
				writing-mode: vertical-lr;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.table-data {
				flex: 1;
				height: max-content;

				.table-row-wrap {
					display: grid;
					grid-template-columns: repeat(27, 1fr);
					background-color: rgba(180, 75, 122, 0.2);
					flex: 1;
					margin-bottom: 2px;

					&:first-child {
						background-color: rgba(180, 75, 122, 0.5);
					}
					&:last-of-type {
						margin-bottom: 0;
					}
					.table-row {
						display: flex;
						align-items: center;
						justify-content: center;
						color: #ffffff;
						position: relative;
						padding: 7px 0;
						font-weight: bold;
						font-size: 0.9vw;
						&:after {
							content: '';
							position: absolute;
							top: 50%;
							transform: translateY(-50%);
							height: 8px;
							width: 2px;
							background-color: rgba(255, 255, 255, 0.2);
							right: 0;
						}
						&:first-of-type {
							grid-column-start: 1;
							grid-column-end: 4;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #ffcde3;
							&:after {
								background-color: rgba(255, 255, 255, 0.7);
							}
						}
						&:last-child {
							&:after {
								background-color: transparent;
							}
						}
					}
					&.head {
						background-color: rgba(180, 75, 122, 0.7);
						cursor: pointer;
						.table-row {
							font-weight: normal;
							&:after {
								background-color: transparent;
							}
						}
					}
				}
			}
		}
	}
}
</style>
