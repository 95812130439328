<template>
	<div id="ring"></div>
</template>
<script>
import { Pie } from '@antv/g2plot';

export default {
	props: {
		crowd: { type: Array, default: () => [] },
	},
	data() {
		return {
			piePlot: null,
		};
	},
	methods: {
		initChart() {
			this.piePlot = new Pie('ring', {
				data: this.crowd,
				appendPadding: [30, 10, 30, 10],
				angleField: 'value',
				colorField: 'type',
				renderer: 'svg',
				color: this.crowd.map((item) => item.color),
				pieStyle: {
					stroke: 'transparent',
					lineOpacity: 0,
					lineWidth: 0,
				},
				radius: 1,
				innerRadius: 0.7,
				label: {
					type: 'spider',
					content: (item) => item.type,
					style: {
						fill: 'white',
					},
					layout: 'overlap',
				},
				interactions: [{ type: 'element-selected' }, { type: 'element-active' }],
				legend: false,
				statistic: {
					title: false,
					content: false,
				},
			});
			this.piePlot.render();
		},
	},
	mounted() {},
	watch: {
		crowd: {
			handler() {
				this.$nextTick(() => {
					this.piePlot && this.piePlot.destroy();
					this.initChart();
				});
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
<style lang="scss" scoped>
#ring {
	height: 80%;
	width: 100%;
}
</style>
