<template>
	<div class="histogram" :id="id"></div>
</template>
<script>
import { Column } from '@antv/g2plot';
export default {
	props: {
		id: {
			default: '',
			type: String,
		},
		crowd: {
			default: () => [],
			type: Array,
		},
	},
	data() {
		return {
			columnPlot: null,
		};
	},
	methods: {
		initChart() {
			this.columnPlot = new Column(this.id, {
				data: this.crowd,
				appendPadding: 16,
				xField: 'type',
				yField: 'value',
				label: {
					// 可手动配置 label 数据标签位置
					position: 'top', // 'top', 'bottom', 'middle',
					// 配置样式
					style: {
						fill: '#FFFFFF',
					},
					content: (item) => `${item.value}次`,
				},
				xAxis: {
					label: {
						autoHide: true,
						autoRotate: false,
					},
				},
				seriesField: 'type',
				legend: false,
				columnStyle: {
					fill: 'l(250) 0:#EC8B6B 1:#ED6E9F',
				},
				minColumnWidth: 20,
				maxColumnWidth: 20,
			});
			this.columnPlot.render();
		},
	},
	mounted() {
		this.initChart();
	},
	watch: {
		crowd: {
			handler() {
				this.$nextTick(() => {
					this.columnPlot && this.columnPlot.destroy();
					this.initChart();
				});
			},
			immediate: true,
			deep: true,
		},
	},
};
</script>
<style lang="scss" scoped>
.histogram {
	width: 100%;
	height: 100%;
}
</style>
